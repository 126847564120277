import React from "react"
import Navbar from "./Navbar"
import logoFooter from "../assets/logoFooter.svg"
import { useState } from "react"
import { useEffect } from "react"

export default function Layout({
  fontBigger,
  fontSmaller,
  language,
  children,
  trad,
}) {
  const [project, setProject] = useState()

  useEffect(async () => {
    const project = window.location.host.split(".")[0]
    document.querySelector("body").classList.add(`theme-${project}`)

    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement("link")
      link.rel = "icon"
      document.getElementsByTagName("head")[0].appendChild(link)
    }

    try {
      const favicon = await import(`../assets/${project}/favicon.ico`)
      link.href = favicon.default
    } catch (error) {
      const favicon = await import(`../assets/default/favicon.ico`)
      link.href = favicon.default
    }

    setProject(project)
  }, [])

  return (
    <div className="layout">
      <Navbar
        language={language}
        fontBigger={() => fontBigger()}
        fontSmaller={() => fontSmaller()}
        project={project}
        trad={trad}
      />
      <div className="content">{children}</div>
      <footer>
        <div style={{ textAlign: "center", padding: "5%" }}>
          <img alt="Curecall logo" src={logoFooter} />
          <h5>Powered by Curecall</h5>
        </div>
      </footer>
    </div>
  )
}
