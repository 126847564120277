import React, { useEffect, useState } from "react"
import SquareButton from "./SquareButton"
import { navigate } from "gatsby"

const getNavbarImageStyle = project => {
  switch (project) {
    case "pointvisionmarseille":
      return {
        width: "100%",
      }
    case "pointvision":
      return {
        width: "50%",
      }
    case "clinique-honore-cave":
      return {
        width: "30%",
      }
    case "clinique-mathilde-rouen":
      return {
        width: "100%",
      }
    default:
      return
  }
}

export default function Navbar({
  fontBigger,
  fontSmaller,
  language,
  project,
  trad,
}) {
  const [nextLanguage, setNextLanguage] = useState(null)
  const [icon, setIcon] = useState()

  useEffect(async () => {
    try {
      const importedIcon = await import(`../assets/${project}/logo.svg`)
      setIcon(importedIcon.default)
    } catch (error) {
      const importedIcon = await import(`../assets/default/logo.svg`)
      setIcon(importedIcon.default)
    }
  })

  useEffect(() => {
    if (language == "fr") {
      setNextLanguage("en")
    } else {
      setNextLanguage("fr")
    }
  }, [language])

  function changelanguage() {
    const actualLocation = document.location.toString().split("/")
    actualLocation.length === 5
      ? navigate("/" + nextLanguage + "/" + actualLocation[4])
      : navigate(
          "/" + nextLanguage + "/" + actualLocation[4] + "/" + actualLocation[5]
        )
  }

  function handleBigger() {
    fontBigger()
  }

  function handleSmaller() {
    fontSmaller()
  }

  return (
    <nav>
      <div className="grid-logo-language">
        <div className="header">
          <img alt="Logo" src={icon} style={getNavbarImageStyle(project)} />
          {project === "content" && (
            <h2 style={{ marginLeft: 10 }}>Votre cabinet d'ophtamologie</h2>
          )}
        </div>
        {trad && (
          <div className="language">
            <SquareButton
              onClick={() => changelanguage()}
              label={nextLanguage ? nextLanguage.toUpperCase() : ""}
              className="square-button-language"
              height="3.5rem"
              width="3.5rem"
            />
          </div>
        )}
      </div>
      <div className="resizers">
        <SquareButton
          onClick={() => handleSmaller()}
          label="-A"
          className="square-button-minus"
          height="3rem"
          width="3rem"
        />
        <SquareButton
          onClick={() => handleBigger()}
          className="square-button-plus"
          label="+A"
          height="3.5rem"
          width="3.5rem"
        />
      </div>
      <hr />
    </nav>
  )
}
