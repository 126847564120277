import React from "react"

const SquareButton = ({ label, onClick, height, width, className }) => (
  <button
    className={`square-button ${className}`}
    style={{ height: height, width: width }}
    onClick={onClick}
  >
    <p>{label}</p>
  </button>
)

export default SquareButton
